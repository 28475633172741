
import universal, { setHasBabelPlugin } from '/Users/overseer/n/s/eldarlabsreact/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/404'), universalOptions)
const t_1 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages'), universalOptions)
const t_2 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/health/bmi-calculator'), universalOptions)
const t_3 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/timing/stopwatch'), universalOptions)
const t_4 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/timing/alarm-timer'), universalOptions)
const t_5 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/calculators/compound-interest'), universalOptions)
const t_6 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/calculators/financial-adviser-fees-compared'), universalOptions)
const t_7 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/checklist-to-consider-when-investing'), universalOptions)
const t_8 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/compare-online-brokers-australia'), universalOptions)
const t_9 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/buying-silver-in-australia'), universalOptions)
const t_10 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/buying-gold-in-australia'), universalOptions)
const t_11 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/finance/etoro-review'), universalOptions)
const t_12 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/tos'), universalOptions)
const t_13 = universal(import('../node_modules/finx/lib/site/eldarlabs/pages/disclaimer'), universalOptions)


// Template Map
export default {
  '../node_modules/finx/lib/site/eldarlabs/pages/404': t_0,
'../node_modules/finx/lib/site/eldarlabs/pages': t_1,
'../node_modules/finx/lib/site/eldarlabs/pages/health/bmi-calculator': t_2,
'../node_modules/finx/lib/site/eldarlabs/pages/timing/stopwatch': t_3,
'../node_modules/finx/lib/site/eldarlabs/pages/timing/alarm-timer': t_4,
'../node_modules/finx/lib/site/eldarlabs/pages/calculators/compound-interest': t_5,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/calculators/financial-adviser-fees-compared': t_6,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/checklist-to-consider-when-investing': t_7,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/compare-online-brokers-australia': t_8,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/buying-silver-in-australia': t_9,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/buying-gold-in-australia': t_10,
'../node_modules/finx/lib/site/eldarlabs/pages/finance/etoro-review': t_11,
'../node_modules/finx/lib/site/eldarlabs/pages/tos': t_12,
'../node_modules/finx/lib/site/eldarlabs/pages/disclaimer': t_13
}

export const notFoundTemplate = "../node_modules/finx/lib/site/eldarlabs/pages/404"
