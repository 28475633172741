import React from "react"
import ReactDOM from "react-dom"
// import { AppContainer } from "react-hot-loader";

export function renderApp(App) {
  if (typeof document !== "undefined") {
    const renderMethod = module.hot
      ? ReactDOM.render
      : ReactDOM.hydrate || ReactDOM.render
    const render = Comp => {
      renderMethod(<Comp />, document.getElementById("root"))
    }
    render(App)
  }
  // Hot Module Replacement
  if (module.hot) {
    module.hot.accept("../App", () => render(require("../App").default))
  }
  // if (typeof document !== "undefined") {
  //   const target = document.getElementById("root");
  //
  //   const renderMethod = target.hasChildNodes()
  //     ? ReactDOM.hydrate
  //     : ReactDOM.render;
  //
  //   const render = Comp => {
  //     renderMethod(
  //       <AppContainer>
  //         <Comp />
  //       </AppContainer>,
  //       target,
  //     );
  //   };
  //
  //   // Render!
  //   render(App);
  //
  //   // Hot Module Replacement
  //   if (module && module.hot) {
  //     module.hot.accept("../App", () => {
  //       render(App);
  //     });
  //   }
  // }
}
