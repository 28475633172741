"use strict";

exports.__esModule = true;
exports.config = void 0;
var config = {
  // blogPostDir: "posts", // The name of directory that contains your posts.
  siteTitle: "Eldar Labs",
  // Site title.
  siteTitleAlt: "",
  // Alternative site title for SEO.
  // siteLogo: "/icons/icon-512.png", // Logo used for SEO and manifest.
  siteUrl: "https://eldarlabs.com",
  // Domain of your website without pathPrefix.
  pathPrefix: "/",
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  siteDescription: "",
  // Website description used for RSS feeds/meta description tag.
  manifestPath: "/manifest.json",
  // favicon16Path: "/favicon-16x16.png",
  // favicon32Path: "/favicon-32x32.png",
  // appleTouchIcon: "/apple-touch-icon.png",
  // path and color comma separated
  // maskIcon: "/safari-pinned-tab.svg, #501eb4",
  backgroundColor: "#fff",
  // themeColor: "#501EB4",
  themeColor: "#6c46b9",
  // tileColor: "#ffc40d",
  // siteRss: "/rss.xml", // Path to the RSS file.
  userTwitter: "@EldarLabs",
  // siteFBAppID: "", // FB Application ID for using app insights
  // userFacebook: "EldarLabs",
  siteGATrackingID: "UA-54389315-12" // Tracking code ID for google analytics.
  // userName: "TravelWebway", // Username to display in the RSS feed.
  // amazonTrackingId: "get-coffee-20",
  // copyright: "Copyright © Eldar Labs", // Copyright string for the footer of the website and RSS feed.

};
exports.config = config;