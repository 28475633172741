"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.CollapseMenu = void 0;

require("antd/lib/menu/style");

var _menu = _interopRequireDefault(require("antd/lib/menu"));

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _styled = require("../../styles/styled");

var _Core = require("../Core");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  .ant-menu-item, .ant-menu-submenu-title {\n    padding: 0 11px;\n  }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  && {\n    white-space: normal;\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var CollapseMenu = (0, _styled.styled)(_menu.default)(_templateObject(), _Core.media.screenSm(_templateObject2()));
exports.CollapseMenu = CollapseMenu;